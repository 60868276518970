<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/tipos-assinaturas"
  >
    <h4 class="text-center">Dados básicos</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Nome da assinatura"
      v-model="form.nome"
      :validation="$v.form.nome"
    />
    <FormField
      label="Fator de preço"
      v-model="form.fator"
      typeField="number"
      help="O fator preço irá determinar quanto do valor original deverá valer este produto. <br>
        Exemplo: <br>
        Produto A - R$ 10,00 <br>
        Fator preço - 0,5 (50% do valor original) <br>
        Cálculo: <br>
        Produto A x Fator Preço = Preço do produto com desconto <br>
        R$10,00 x 0,5 = R$ 5,00 este será o preço do produto dentro da vigência desta política."
      step="0.01"
      :validation="$v.form.fator"
    />
    <FormField
      label="Repetição ocorre por"
      v-model="form.dias_vigencia"
      typeField="number"
      help="Tempo de duração da assinatura em dias. Depois de passado esse tempo a assinatura deixa de gerar pedidos automaticamente. </br>Ex.: Se deve gerar pedidos por um ano, configure 365. Para gerar pedidos por 2 anos, configure 730."
      :validation="$v.form.dias_vigencia"
    />
    <v-row no-gutters>
      <v-col>
        <FormDatePicker
          v-model="form.data_disponibilidade"
          label="Valido até"
          :min="currentDate"
          :validation="$v.form.data_disponibilidade"
        />
      </v-col>
      <v-col cols="auto" class="pl-3">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mt-2">far fa-question-circle</v-icon>
          </template>
          Data limite para que essa assinatura esteja disponível para escolha
          pelo cliente. Depois do final desse prazo ela desaparece da seleção no
          carrinho.
        </v-tooltip>
      </v-col>
    </v-row>
    <FormSelect
      label="Cobrança"
      v-model="form.recorrencia"
      :options="recorrencias"
    />
    <FormSelect
      label="Entrega"
      v-model="form.recorrenciaEntrega"
      :options="recorrencias"
    />
    <p>
      <b>Termo de uso</b>
      (<a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
        >dicas de formatação</a
      >)
    </p>
    <Simplemde v-model="form.termo_uso" />
    <small v-if="$v.form.termo_uso.$invalid" class="red--text">
      O termo de uso é obrigatório
    </small>

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormSelect from '@/components/form/FormSelect'
import LineDivider from '@/components/LineDivider'
import { RECORRENCIA } from '@/store/actions/assinatura'
import { ASSINATURA_CREATE } from '@/store/actions/assinatura'
import { required, minValue } from 'vuelidate/lib/validators'
import Simplemde from 'vue-simplemde'
import * as _ from 'lodash'
import * as moment from 'moment'

export default {
  name: 'ConfiguracaoForm',
  components: {
    SidePopup,
    FormButton,
    LineDivider,
    FormField,
    FormDatePicker,
    FormSelect,
    Simplemde
  },
  data: () => ({
    isLoading: false,
    form: {
      nome: '',
      recorrencia: RECORRENCIA.COMPRA_UNICA.value,
      recorrenciaEntrega: RECORRENCIA.COMPRA_UNICA.value,
      dias_vigencia: 30,
      data_disponibilidade: '',
      fator: 0
    },
    currentDate: moment().format('YYYY-MM-DD'),
    recorrencias: Object.keys(RECORRENCIA).map(key => {
      return {
        text: RECORRENCIA[key].name,
        value: RECORRENCIA[key].value
      }
    })
  }),
  validations: {
    form: {
      nome: { required },
      fator: { required },
      dias_vigencia: { required, minValue: minValue(1) },
      data_disponibilidade: { required },
      termo_uso: { required }
    }
  },
  computed: {
    title() {
      return 'Novo tipo de assinatura'
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/tipos-assinaturas')
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      let assinatura = {
        ...this.form
      }
      this.$store
        .dispatch(ASSINATURA_CREATE, assinatura)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Tipo de assinatura cadastrada com sucesso'
          )
          this.onClose(true)
        })
        .catch(err => {
          const erro = _.get(
            err,
            'responseJSON.message',
            'Não foi possível cadastrar o tipo de assinatura'
          )
          this.$vueOnToast.pop('error', erro)
        })
    }
  }
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';
</style>
